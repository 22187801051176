<template>
  <div class="row">
    <div class="flex xs12">
      <va-card
        class="no-h-padding"
        :title="$t('reports.new')"
      >
        <actions
          slot="actions"
          crud-links="reports"
          controller="Proyections"
          :actions="actions"
        />
        <form-wizard
          title=""
          subtitle=""
          :color="$themes.primary"
          :next-button-text="$t('layout.wizard.next')"
          :back-button-text="$t('layout.wizard.back')"
          :finish-button-text="$t('layout.wizard.finish')"
          @on-complete="submit"
        >
          <tab-content
            :title="$t('reports.wizard.data')"
            :before-change="isReportValid"
          >
            <div class="text-center">
              <p><strong>{{$t('reports.wizard.form.data')}}</strong></p>
            </div>
            <data-form
              ref="reportForm"
              :wizard="true"
              :report="report"
              :loading="loading"
              :previous-months="previousMonths"
              :max-preaching-points="maxPreachingPoints"
              @team-selected="getConfigParameters"
              @submit="saveForm"
            />
          </tab-content>
          <tab-content
            :title="$t('reports.wizard.presentations')"
            :before-change="isPresentationsValid"
          >
            <div class="text-center">
              <p><strong>{{$t('reports.wizard.form.presentations', {min: minPresentations})}}</strong></p>
            </div>
            <presentations-table
              ref="presentationsTable"
              :edit="true"
              :report="report"
              :loading="loading"
              @submit="savePresentations"
            />
          </tab-content>
          <tab-content
            v-if="noEnoughPresentations"
            :title="$t('reports.wizard.reason')"
            :before-change="isReasonsValid"
          >
            <div class="text-center">
              <p><strong>{{$t('reports.wizard.form.reason', { min: minPresentations })}}</strong></p>
            </div>
            <div class="row">
              <div class="flex xs12">
                <va-input
                  color="info"
                  v-model="presentationReason"
                  type="textarea"
                  :autosize="true"
                  :min-rows="5"
                  :maxlength="250"
                  :disabled="loading"
                  :label="$t('reports.inputs.reasonInput')"
                />
              </div>
            </div>
          </tab-content>
          <tab-content :title="$t('teams.wizard.confirm')">
            <div class="text-center">
              <transition-group
                name="fade"
                mode="out-in"
              >
                <div
                  key="review"
                  v-show="!loading && !submitError"
                >
                  <div class="text-center">
                    <h4>{{$t('reports.wizard.form.confirm')}}</h4>
                    <p>{{$t('reports.wizard.form.verify')}}</p>
                  </div>
                  <reports-view
                    :show-links="false"
                    :current-report="fullReport"
                  />
                </div>
                <div
                  key="processing"
                  v-show="loading"
                >
                  <div class="text-center">
                    <h4>{{$t('reports.wizard.completed')}}</h4>
                    <p>{{$t('reports.wizard.form.completed')}}</p>
                  </div>
                </div>
                <div
                  key="error"
                  v-show="submitError"
                >
                  <div class="text-center">
                    <h4>{{$t('reports.wizard.error')}}</h4>
                    <p>{{$t('reports.wizard.form.submit_error')}}</p>
                    <va-button
                      color="primary"
                      @click.prevent="submit"
                    >
                      {{$t('reports.wizard.retry')}}
                    </va-button>
                  </div>
                </div>
              </transition-group>
            </div>
          </tab-content>
        </form-wizard>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

const DataForm = () => import(/* webpackPrefetch: true */ './Form')
const ReportsView = () => import(/* webpackPrefetch: true */ './View')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const PresentationsTable = () => import(/* webpackPrefetch: true */ './presentations/Table')

export default {
  name: 'reports-new',
  components: {
    DataForm,
    ReportsView,
    Actions,
    PresentationsTable,
    FormWizard,
    TabContent,
  },
  data () {
    return {
      loading: false,
      submitError: false,
      actions: ['index'],
      noEnoughPresentations: false,
      presentationReason: '',
      fullReport: {},
      report: {},
      presentations: [],
      previousMonths: 2,
      minPresentations: 4,
      maxPreachingPoints: 1,
      preachingPoints: [],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    reportChange () {
      this.$refs.reportForm.submit()
    },
    isReportValid () {
      this.$refs.reportForm.validateAll()
      if (!this.$refs.reportForm.formReady) return false

      if (!this.$refs.reportForm.getFormValue('testimony_en')) {
        this.showToast(this.$t('notifications.alerts.testimony_translation_needed'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return false
      }
      this.reportChange()
      return true
    },
    presentationChange () {
      this.$refs.presentationsTable.submit()
    },
    isPresentationsValid () {
      this.presentationChange()
      return true
    },
    reasonChange () {
      this.report.observations = this.presentationReason
      this.fullReport.observations = this.presentationReason
      this.fullReport = Object.assign({}, this.fullReport)
    },
    isReasonsValid () {
      const empty = this.presentationReason.length === 0
      if (empty) return false

      this.reasonChange()
      return true
    },
    routeBuilder () {
      return 'reports/districts'
    },
    createUndefinedDistrict () {
      // let noDef = this.$t('tables.undefined')

      let district = this.currentUser.district
      let country = this.currentUser.country
      if (!district) {
        district = {
          name: '',
          country: {},
        }
      }
      if (!country) {
        country = {
          name: '',
        }
      }

      this.fullReport.district = district
      this.fullReport.district.country = country
    },
    createUndefinedTeam () {
      // let noDef = this.$t('tables.undefined')

      let team = this.currentUser.team
      if (!team) {
        team = {
          code: '',
        }
      }
      this.fullReport.team = team
    },
    async adjustFullProyection () {
      this.fullReport = Object.assign({}, this.report)
      this.fullReport.distrital_approval = 0
      this.fullReport.country_approval = 0

      let u = false
      if (this.currentUser.can('Districts', 'view')) {
        try {
          u = await this.$http.get('districts/' + this.fullReport.district_id)
        } catch (e) {
          // console.log('error', e)
        }
      }
      u === false
        ? this.createUndefinedDistrict()
        : this.fullReport.district = u.data.data

      let t = false
      if (this.currentUser.can('Teams', 'view')) {
        try {
          t = await this.$http.get('teams/' + this.fullReport.team_id)
        } catch (e) {
          // console.log('error', e)
        }
      }
      t === false
        ? this.createUndefinedTeam()
        : this.fullReport.team = t.data.data
    },
    async saveForm (data) {
      if (!data) return

      this.report = Object.assign({}, data.report)
      await this.adjustFullProyection()
      this.preachingPoints = []
      for (const pp of data.preachingPoints) {
        this.preachingPoints.push({
          id: pp.id,
          planted_date: pp.preaching_date,
          code: pp.preaching_code,
          name: pp.type_name,
          assistance_adults: pp.type_asistance,
          leader_id: pp.leader_id,
          new: pp.new,
          type_district: pp.type_district,
        })
      }
      this.fullReport.preaching_points = data.preachingPoints.slice(0).map(pp => ({
        id: pp.id,
        planted_date: pp.preaching_date,
        code: pp.preaching_code,
        name: pp.type_name,
        leader_id: pp.leader_id,
        leader: pp.leader,
        type_district: pp.type_district,
        assistance_adults: pp.type_asistance,
        assistance_youngs: 0,
        assistance_kids: 0,
      }))
    },
    savePresentations (presentations) {
      this.noEnoughPresentations = presentations.length < this.minPresentations

      this.presentations = presentations.slice(0)
      this.fullReport.presentations = this.$refs.presentationsTable.presentationsList.slice(0)
      this.fullReport = Object.assign({}, this.fullReport)
    },
    async getConfigParameters (team) {
      const queries = ['q=parameters.names.min_presentations,parameters.names.max_preaching_points,parameters.names.max_reports_months']
      if (team.region_id) {
        queries.push(`region=${team.region_id}`)
      }

      let response = false
      try {
        response = await this.$http.get(`parameters/user?${queries.join('&')}`)
      } catch (e) {
        // console.log('error', e)
        this.submitError = true
        this.loading = false
        return
      }

      const data = response.data.data
      const minPresentations = data.find(x => x.name === 'parameters.names.min_presentations')
      if (minPresentations) {
        this.minPresentations = Number(minPresentations.value)
      }
      const maxPreachingPoints = data.find(x => x.name === 'parameters.names.max_preaching_points')
      if (maxPreachingPoints) {
        this.maxPreachingPoints = Number(maxPreachingPoints.value)
      }
      const previousMonths = data.find(x => x.name === 'parameters.names.max_reports_months')
      if (previousMonths) {
        this.previousMonths = Number(previousMonths.value)
      }
    },
    async submit () {
      if (this.loading) return

      this.loading = true
      this.submitError = false

      let response = false
      try {
        response = await this.$http.post(this.routeBuilder(), this.report)
      } catch (e) {
        // console.log('error', e)
        this.submitError = true
        this.loading = false
        return
      }

      const reportId = response.data.data.id
      const preaching = await this.addPreachingPoint(this.preachingPoints)
      if (preaching.length > 0) {
        await this.submitPreachingPoints(reportId, preaching)
      }
      this.submitPresentations(reportId)
    },
    async submitPresentations (reportId) {
      const data = {
        id: reportId,
        presentations: {
          _ids: this.presentations,
        },
      }

      try {
        await this.$http.put(`proyections/${reportId}`, data)
      } catch (e) {
        // console.log('error submitting presentations', e)
        this.submitError = true
        this.loading = false
        return
      }

      this.showToast(this.$t('reports.wizard.notification'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'reportsIndex' })
    },
    async addPreachingPoint (points) {
      const preachings = points.filter(x => !!x.id).map(x => x.id)
      const report = Object.assign({}, this.fullReport)
      const newPreachings = points.filter(x => x.new)
      for (const p of newPreachings) {
        const data = {
          team_id: report.team_id,
          district_id: report.district_id,
          country_id: report.district.country_id,
          region_id: report.district.country.region_id,
          leader_id: p.leader_id,
          planted_date: p.planted_date ? p.planted_date : report.proyection_date,
          code: p.code,
          name: p.name,
          address: p.name,
          assistance_kids: 0,
          assistance_youngs: 0,
          assistance_adults: p.assistance_adults,
        }

        let response = null
        try {
          response = await this.$http.post('preaching-points', data)
        } catch (e) {
          // console.log('error submitting presentations', e)
          this.submitError = true
          this.loading = false
          return
        }
        if (response.data.data.id) {
          preachings.push(response.data.data.id)
        }
      }

      return preachings
    },
    async submitPreachingPoints (reportId, preachingPoints) {
      const data = {
        id: reportId,
        preaching_points: {
          _ids: preachingPoints,
        },
      }

      try {
        await this.$http.put(`proyections/${reportId}`, data)
      } catch (e) {
        // console.log('error submitting presentations', e)
        this.submitError = true
        this.loading = false
      }
    },
  },
}
</script>
